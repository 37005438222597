import showcaseimg from "../../../../../assets/images/about-img-left.png";
import showcaseimg1 from "../../../../../assets/images/burger-icon.png";
import "../../../../../App.css";
// import whitespace from "../../../../../assets/css/custom.css";
import { Container, Row, Col, Button, h1, p, h6, hr } from "react-bootstrap";

export const ShowCase = () => {
  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img className="img-sm" src={showcaseimg} />
        </Col>
        <Col xs={6} md={5} >
         <div className="left margin-top-20">
          <h1>About Us</h1>
          <h4>Get to know us</h4>
          </div>
          
          <div className="left margin-top-20">
           <p> AJ Sliders is an ambiguous restaurant venture focused on providing
            great-tasting food that is low-carb, gluten-free, wheat-free,
            sugar-free, and soy-free while serving the community as a 'business
            as a mission,' sharing the love of Jesus Christ.
          </p>
          </div>
          <div className="showcase-right-text left">
         <Col xs={6} md={3} className="img-icon" >
           <img src={showcaseimg1} size="25" />
            </Col>
          <p>
          <h4> Our Menu </h4>
           Your choice of protein and sauce served on our low-carb Power Bread!
          <h4></h4>
           <Button className="btn-red margin-top-10 margin-bottom-10" href="/menu" >
                  Order Now{" "}
                </Button>
           
          </p>

          </div>    
        </Col>
       


      </Row>
    </Container>
  );
};
