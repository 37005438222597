import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/ajlion.svg";

export default function BottomLanding() {
  return (
<a href="/menu">
    <React.Fragment>
      <AppBar position="fixed" sx={{ background: "#bbbac5", top: 'auto', bottom: 0 }}>
      <div className="footer-area padding-top-20">
        <div className="footer-top d-md-block">
          <div className="container">
            <div className="row align-items-center justify-content-between padding-bottom-25">
              <div className="col-lg-3 col-md-3">
                <div className="f-logo">
                  <a href="/menu">
                    {" "}
                    <img className="App-logo" src={Logo} alt="AjSliders" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="f-title">
                  <h1>
                    Tap anywhere to<span> start</span>
                  </h1>
                </div>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="footer-bottom padding-top-22 padding-bottom-30">
          <div className="container">
            <div className="row">
            </div>
          </div>
        </div>
      </div>
      </AppBar>
    </React.Fragment>
</a>
  );
}
