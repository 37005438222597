/*import "../../../../../App.css";*/
import "../../../../../assets/css/custom.css";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {
  Container,
  Row,
  Col,
  Button,  
  Card,
  CardGroup,
  CardImg,
  h5,
  p,
  h1,
  ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DiscoverMenu = () => {
  return (
<a href="/menu">
    <div className="MainContainerImg">
     
    </div>
</a>
  );
};
