import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function BusinessCard() {
  return (
    <Card>
      <CardActionArea sx={{ backgroundColor: '#ff9933', cursor: 'default !important' }}>
        <CardMedia
          component="img"
          height="140"
          image="/static/business-hours.png"
          alt="Business Hours"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Business Hours
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            Monday - Saturday: 9:00AM - 7:00PM
              <br></br>
            Sunday:CLOSED
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}