import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function DirectionCard() {
  return (
    <Card>
      <CardActionArea sx={{ backgroundColor: '#ff9933', cursor: 'default !important'  }}>
        <CardMedia
          component="img"
          height="140"
          image="/static/get-direction.png"
          alt="Get Direction"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Get Direction
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            2075 Prairie Street, Suite 110
            <br></br>  
            Saint Charles, IL 60174
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}