import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function ContactCard() {
  const [value, setValue] = React.useState('Controlled');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography variant="h3" gutterBottom component="div">
        Write To Us
      </Typography>
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="outlined-required"
          label="Complete Name"
          defaultValue="Name"
        />
        <TextField
          required
          id="outlined-required"
          label="Email Address"
          defaultValue="Email"
        />
          {/*<TextField
          outlined
          id="outlined"
          label="Website"
          defaultValue="www.sitename.com"
        />*/}
        <TextField
          id="outlined-multiline-flexible"
          label="Write your Message"
          multiline
          maxRows={4}
          value={value}
          onChange={handleChange}
        />
      </div>
    </Box>
      </CardContent>
      <CardActions>
              <Button className="btn-red margin-top-10 margin-bottom-10" href="#" >
                  Send Message{" "}
                </Button>
      </CardActions>
    </Card>
  );
}