import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from "../../../assets/images/AJ_Sliders_LogoSqr.svg";
import { Container, Row, Col, Button, h1, p, h6, hr } from "react-bootstrap";
import { MenuCarousel } from "../components/MenuCarousel";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 128,
  },
}));

export default function MenuAppBar() {
  return (
     
    <Box sx={{ flexGrow: 1 }}>
          
      <AppBar className="p-0" position="fixed" sx={{ boxShadow: 0, top: 0, backgroundColor: "white" }}>  
        <div>
        <MenuCarousel /> 
        </div> 
        <StyledToolbar style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            
      <Col className="left" xs={12}>
        <h2 className="dark" style={{ paddingTop: "5px" }}>
          <img className="App-logo" src={Logo} alt="AjSliders" /> Changing lives, one slider at a time!
        </h2>
      </Col>
        </StyledToolbar>
      </AppBar>
    </Box>
  );
}
