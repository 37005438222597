import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Cart } from "../cart";

export default function BottomAppBar() {
  return (
    <React.Fragment>
      <AppBar position="fixed" sx={{ background: "#bbbac5", top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ '& button': { m: 1 } }}>
            <div>
                <a href="/menu">
                  <Button variant="contained" size="large" style={{"width":"16rem", "height":"7rem", "background-color":"#A20F1C"}}>
                    Back
                  </Button>
                </a>
                
                <a href="/checkout">
                  <Button color="success" variant="contained" size="large" style={{"width":"16rem", "height":"7rem", "background-color":"#3D5309"}}>
                    Checkout
                    <Cart/>
                  </Button>
                </a>
            </div>
          </Box>          
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
