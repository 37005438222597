import React from "react";
// import center from "../../../assets/css/custom.css";
// import "../../../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../assets/images/ajlion.svg";
import CookieConsent from "react-cookie-consent";
import { FaHeart } from "react-icons/fa";
import {
  Container,
  Button,
  Row,
  Col,
  h5,
  p,
  h1,
  Card,
  Nav,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const Footer = () => {
  const history = useHistory();
  return (
<a href="/menu">
    <div position="fixed" sx={{ top: 'auto', bottom: 0 }}>
      <div className="footer-area padding-top-40">
        <div className="footer-top d-md-block">
          <div className="container">
            <div className="row align-items-center justify-content-between padding-bottom-25">
              <div className="col-lg-3 col-md-3">
                <div className="f-logo">
                  <a href="/menu">
                    {" "}
                    <img className="App-logo" src={Logo} alt="AjSliders" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="f-title">
                  <h1>
                    Tap anywhere to<span> start</span>
                  </h1>
                </div>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="footer-bottom padding-top-22 padding-bottom-30">
          <div className="container">
            <div className="row">
            </div>
          </div>
        </div>
      </div>
    </div>
</a>
  );
};
