import { Row, CardGroup, Card, CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../assets/images/1.jpg";
import Slider2 from "../../../assets/images/2.jpg";
import Slider3 from "../../../assets/images/3.jpg";
import Slider4 from "../../../assets/images/4.jpg";
import Slider5 from "../../../assets/images/5.jpg";
import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';

export const MenuCarousel = () => {
    
useEffect(() => {
document.title = "Gluten-Free Restaurant"
}, [])    
    
  return (

<div>
<Carousel fade variant="dark">

<Carousel.Item>
    <Grid sx={{ flexGrow: 1 }} container spacing={2} className="gx-0" >
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider1} alt="First slide" />
        </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider2} alt="First slide" />  
         </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider3} alt="First slide" />  
        </Grid>
    </Grid>
</Carousel.Item>
          
<Carousel.Item>
    <Grid sx={{ flexGrow: 1 }} container spacing={2} className="gx-0" >
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider2} alt="First slide" />
        </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider3} alt="First slide" />  
         </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider4} alt="First slide" />  
        </Grid>
    </Grid>
</Carousel.Item>

<Carousel.Item>
    <Grid sx={{ flexGrow: 1 }} container spacing={2} className="gx-0" >
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider3} alt="First slide" />
        </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider4} alt="First slide" />  
         </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider5} alt="First slide" />  
        </Grid>
    </Grid>
</Carousel.Item>
    
<Carousel.Item>
    <Grid sx={{ flexGrow: 1 }} container spacing={2} className="gx-0" >
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider4} alt="First slide" />
        </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider5} alt="First slide" />  
         </Grid>
                
        <Grid className="gx-0" style={{ marginLeft: 0, marginRight: 0 }} xs={4} md={4} >
          <img className="float-left w-20 carousel-fade" src={Slider1} alt="First slide" />  
        </Grid>
    </Grid>
</Carousel.Item>           
    
</Carousel>
</div>

  );
};
